import React from "react";

function Method({ year, month, day, dayOfWeek, daysOfWeek }) {
  let century = Math.floor(year / 100);
  let anchorValue = ((5 * (century % 4)) % 7) + 2;
  let decade = year % 100;

  let monthNegs = {
    1: 4,
    2: 0,
    3: 0,
    4: 3,
    5: 5,
    6: 1,
    7: 3,
    8: 6,
    9: 2,
    10: 4,
    11: 0,
    12: 2,
  };

  let d;
  let d2;
  let d3;
  let d4;
  // IF THE DECADE ISN'T A MULTIPLE OF 2 ADD 11 TO MAKE IT EVEN

  if (decade % 2) {
    d = decade + 11;
  } else {
    d = decade;
  }

  // DIVIDE THIS NUMBER BY 2

  d2 = d / 2;

  // IF THIS NUMBER IS ODD THEN ADD 11 TO MAKE IT EVEN

  if (d2 % 2) {
    d3 = d2 + 11;
  } else {
    d3 = d2;
  }

  // FIND THE REMAINDER WHEN DIVIDING THIS NUMBER BY 7

  d4 = d3 % 7;

  let doomsday;
  doomsday = (anchorValue + 7 - d4) % 7;

  return (
    <div id="method">
      <h1>The method</h1>

      <p>Applying the Doomsday algorithm involves three steps:</p>
      <ul>
        <li>determination of the anchor day for the century</li>
        <li>
          calculation of the anchor day for the year from the one for the
          century
        </li>
        <li>
          selection of the closest date out of those that always fall on the
          doomsday and deducing the given date's day of the week
        </li>
      </ul>
      <table>
        <tr>
          <td>0</td>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
        </tr>
        <tr>
          <td>Sunday</td>
          <td>Monday</td>
          <td>Tuesday</td>
          <td>Wednesday</td>
          <td>Thursday</td>
          <td>Friday</td>
          <td>Saturday</td>
        </tr>
      </table>
      <section className="accordion">
        <h2>Step 1: Century anchor</h2>
        <div className="accordion-content">
          <div className="explanation">
            <p>The century anchor is calculated using the following formula:</p>
            <p>(5 x (c % 4)) + 2</p>
            <p>Where c = the given century</p>
          </div>
          <p>The century is {century}</p>
          <p>The remainder when dividing the century by 4 is {century % 4}</p>
          <p>
            5 multiplied by {century % 4} is {5 * (century % 4)}
          </p>
          <p>
            The remainder when dividing this value by 7 is{" "}
            {(5 * (century % 4)) % 7}
          </p>
          <p>Finally, add 2 to get {((5 * (century % 4)) % 7) + 2}</p>
          {anchorValue > 6 ? (
            <p>
              As this value is greater than 6, subtract 7 to get{" "}
              {anchorValue - 7}
            </p>
          ) : null}
          <p className="tip">
            Try to remember that the century anchor for {century}00 to{" "}
            {century + 1}
            00 is always {anchorValue > 6 ? anchorValue - 7 : anchorValue}
          </p>
        </div>
      </section>

      <section className="accordion">
        <h2>Step 2: Year anchor</h2>
        <div className="accordion-content">
          <div className="logicdiagram">
            <div className="query">even?</div>
            <div className="arrow yes">&rarr;</div>
            <div className="divide">Divide by 2</div>
            <div className="arrow">&rarr;</div>
            <div className="query">even?</div>
            <div className="arrow yes">&rarr;</div>
            <div className="final">7 - (Y % 7)</div>
            <div className="arrow no">&darr;</div>
            <div></div>
            <div></div>
            <div className="arrow no">&darr;</div>
            <div></div>
            <div className="add">Add 11</div>
            <div className="arrow">&rarr;</div>
            <div></div>
            <div className="add">Add 11</div>
            <div className="arrow">&rarr;</div>
          </div>
          <p>The decade is {decade}</p>
          <p>
            {!(decade % 2)
              ? "This is a multiple of 2 so halve it to get " + d2
              : "This is not a multiple of 2 so add 11 to get " + d}
          </p>

          {decade % 2 ? (
            <p>This is a multiple of 2 so halve it to get {d2}</p>
          ) : null}

          {d2 % 2 ? (
            <p>This is not a multiple of 2 so add 11 to get {d3}</p>
          ) : null}
          <p>The remainder when dividing this number by 7 is {d4}</p>
          <p>Subtract this number from 7 to get {7 - d4}</p>
          <p>
            Add this to our anchor value for the century to get{" "}
            {anchorValue + 7 - d4}
          </p>
          {anchorValue + 7 - d4 > 6 && (
            <p>As this value is greater than 6, subtract 7 to get {doomsday}</p>
          )}
          <p>
            Each doomsday in {year} is a {daysOfWeek[doomsday]}
          </p>
        </div>
      </section>

      <section className="accordion">
        <h2>Step 3: Calculate the weekday</h2>
        <div className="accordion-content">
          <div className="explanation">
            <p>
              For the final step, I prefer to use my own method. I memorise a
              value for each month which corresponds to the how many days prior
              to the first day of that month the previous doomsday is.
            </p>

            <p>
              I then add this to the given day of the month and divide by 7 to
              get the remainder. I then add this to the doomsday value to get
              the day of the week.
            </p>
          </div>
          <p>
            The neg value for {month} is {monthNegs[month]}{" "}
          </p>

          <p>
            Add the neg amount from the day of the month to get{" "}
            {day + monthNegs[month]}
          </p>
          <p>
            Divide by 7 and get the remainder to get{" "}
            {(day + monthNegs[month]) % 7}
          </p>
          <p>
            Add this to the doomsday value to get the day of the week:{" "}
            {doomsday + ((day + monthNegs[month]) % 7)}
          </p>
          <h3>Leap year</h3>
          <p>
            {decade} divided by 4 leaves a remainder of {decade % 4} so this is{" "}
            {decade % 4 ? "not a leap year" : "a leap year"} and we{" "}
            {decade % 4 ? "don't" : "do"} need to subtract 1 from the final
            value
          </p>
          <p>This would only affect January and February</p>
          <p>This date is a {daysOfWeek[dayOfWeek]}</p>
        </div>
      </section>
    </div>
  );
}

export default Method;
