import "./App.css";
import Method from "./Method";
import { useState } from "react";

function App({ todaysDate }) {
  // let doomsdays = {
  //   0: 3,
  //   1: 28,
  //   2: 14,
  //   3: 4,
  //   4: 9,
  //   5: 6,
  //   6: 11,
  //   7: 8,
  //   8: 5,
  //   9: 10,
  //   10: 7,
  //   11: 12,
  // };

  let daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let thisYear = todaysDate.getFullYear();
  let thisMonth = todaysDate.getMonth() + 1;
  let thisDay = todaysDate.getDate();

  let [[year, month, day], setDate] = useState([thisYear, thisMonth, thisDay]);
  let [dateChanged, setDateChanged] = useState(false);
  let [dateRevealed, setDateRevealed] = useState(false);

  let dayOfWeek = new Date(year, month - 1, day).getDay();

  let minYear = 1900;
  let maxYear = 2180;
  let difference = maxYear - minYear;

  let randomDate = () => {
    //random year between 1900 and 2180
    let randomYear = Math.floor(Math.random() * difference + minYear);
    let randomMonth = Math.floor(Math.random() * 12 + 1);
    let randomDay = Math.floor(Math.random() * 31 + 1);
    setDate([randomYear, randomMonth, randomDay]);
    setDateChanged(() => true);
    setDateRevealed(() => false);
  };

  return (
    <div className="App">
      <p className="datestate"> {dateChanged ? "Selected date" : "Today"} is</p>
      <p className="chosendate">
        {day} {monthNames[month - 1]} {year}
      </p>

      <input
        type="date"
        value={
          year +
          "-" +
          String(month).padStart(2, "0") +
          "-" +
          String(day).padStart(2, "0")
        }
        onChange={(e) => {
          setDateChanged(() => true);
          setDateRevealed(() => false);
          let date = new Date(e.target.value);
          setDate([date.getFullYear(), date.getMonth() + 1, date.getDate()]);
        }}
      ></input>
      <button className="randomdate" onClick={() => randomDate()}>
        RANDOM DATE
      </button>
      <hr />
      <div
        className={dateRevealed ? "dayofweek shown" : "dayofweek hidden"}
        onClick={() => {
          setDateRevealed(() => true);
        }}
      >
        {daysOfWeek[dayOfWeek]}
      </div>

      <hr />

      <Method
        year={year}
        month={month}
        day={day}
        dayOfWeek={dayOfWeek}
        daysOfWeek={daysOfWeek}
      />
    </div>
  );
}

export default App;
