import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

let todaysDate = new Date();

root.render(
  <React.StrictMode>
    <header>
      <h1>Doomsday calculator</h1>
      <p>
        The{" "}
        <a
          href="https://en.wikipedia.org/wiki/Doomsday_rule"
          target="_blank"
          rel="noreferrer"
        >
          Doomsday Rule
        </a>{" "}
        is an algorithm created by John Conway for calculating the day of the
        week of any given date.
      </p>
    </header>
    <App todaysDate={todaysDate} />
    <footer>
      <a href="https://www.oweng.co.uk" target="_blank" rel="noreferrer">
        {" "}
        <img
          src="https://www.oweng.co.uk/wp-content/uploads/2023/05/owenface.png"
          alt="Owen G logo"
        />
      </a>
      Created by{" "}
      <a href="https://www.oweng.co.uk" target="_blank" rel="noreferrer">
        Owen G
      </a>
    </footer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
